import React from 'react'
import styled from 'styled-components'
import theme from '../theme'

const QuoteBlock = ({
  quote,
  name,
  title,
  photo,
  company,
}: {
  quote: string
  name: string
  title: string
  company: string
  photo: string
}) => {
  return (
    <QuoteStyled>
      <img src={photo} />
      <div className="quote">{quote}</div>
      <div className="author">
        <div className="name">
          <h4 className="author-name">{name}</h4>
          <div className="author-title">{title}</div>
          <div className="author-company">{company}</div>
        </div>
      </div>
    </QuoteStyled>
  )
}

export default QuoteBlock

const QuoteStyled = styled.div`
  min-width: calc(100% - 4px);
  margin: 14px 0;
  background-color: white;
  display: inline-block;
  position: relative;
  box-shadow: 0px 4px 16px 0px rgba(23, 23, 26, 0.04),
    0px 0px 12px 0px rgba(0, 0, 0, 0.03);
  padding: 24px;
  box-sizing: border-box;
  border-radius: ${theme.radii['large']};
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid-column;

  &:nth-child(2n + 1) {
    margin-top: 24px;
  }

  .quote {
    font-size: 1rem;
    line-height: 150%;
  }
  .author {
    margin-top: 24px;
    line-height: 140%;
    display: flex;
    align-items: center;
    font-size: 1rem;

    .author-name {
      margin: 0;
      font-size: 16px;
    }

    .author-company,
    .author-title {
      color: ${theme.colors['black500']};
      font-weight: 500;
    }

    img {
      width: 64px;
      height: auto;
      margin-right: 16px;
    }
  }
`
